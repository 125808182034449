<template>
  <div class="container mx-auto relative z-10">
    <div class="lg:flex justify-between">
      <h3 class="font-funny text-5xl flex items-center justify-center md:justify-start px-2">
        <img src="../../assets/icons/instagram-logo.png" style="width: 32px;" class="mr-4" alt="" loading="lazy">
        Skylantern
      </h3>
      <div class="more-magic-button-wrapper">
        <sky-btn
          class="border-none"
          arrow-right
          arrow-color="sky_secondary"
          style=""
          target="_blank"
          href="https://www.instagram.com/skylantern.fr/"
        >
          Plus d'enchantement
        </sky-btn>
      </div>
    </div>
    <div class="flex overflow-x-auto items-start">
      <div
        v-for="(i, ind) in posts"
        :key="i.id"
        class="sky__instagram-post mx-3"
        :style="{
          transform: ind % 2 ? 'translateY(50px)' : undefined,
          marginBottom: ind % 2 ? 0 : '50px',
          backgroundImage: `url(${i.media_url})`,
          backgroundSize: 'cover'
        }"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    posts: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .more-magic-button-wrapper {
    @apply mb-5 flex justify-center;
    @screen lg {
      @apply mb-0 block;
      position: relative; top: 60px;
    }
  }
  .sky__instagram-post {
    width: 120px;
    height: 120px;
    @screen md {
      width: 150px;
      height: 274px;
    }
    @screen xl {
      width: 274px;
      height: 274px;
    }
  }
</style>
