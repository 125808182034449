<template>
  <div>
    <div class="md:bg-sky_grey_darker py-10 relative mb-4">
      <h3 class="leading-none mb-3 md:absolute text-center md:text-left" style="left: 625px;">
        <span class="md:flex items-center">
          <span class="block">
            <span class="block font-funny text-5xl" style="line-height: 0.6">
              Les recherches de
            </span>
            <span class="block font-bold text-3xl">
              Nos clients
            </span>
          </span>
          <span class="hidden lg:flex" style="position: relative; left: 400px;">
            <span class="sky__searches-arrow-block" @click="cursor--">
              <img style="width: 12px;transform: rotate(90deg)" src="../../assets/icons/nav-select-down.svg?img" alt="">
            </span>
            <span class="sky__searches-arrow-block ml-3" @click="cursor++">
              <img style="width: 12px;transform: rotate(-90deg)" src="../../assets/icons/nav-select-down.svg?img" alt="">
            </span>
          </span>
        </span>
      </h3>
      <div
        class="sky__client-searches-title"
      >
        <div v-for="(i, index) in sortedItems" :key="index">
          <nuxt-link
            :to="i.attributes.lien"
            class="sky__search-tile"
            :class="{
              'sky__search-tile-active': index === 1
            }"
          >
            <img
              :src="`${i.attributes.image}?w=500`"
              alt="Recherche de nos clients"
              loading="lazy"
            >
          </nuxt-link>
        </div>
      </div>
    </div>
    <div class="sky__client-searches-all-button">
      <sky-btn
        class="btn"
        arrow-right
      >
        Toutes les Catégories
      </sky-btn>
    </div>
  </div>
</template>

<script>
import SkyBtn from '../general/SkyLightBtn'
export default {
  components: {
    SkyBtn
  },
  props: {
    searches: {
      required: true,
      type: Array
    }
  },
  data () {
    return {
      cursor: 4
    }
  },
  computed: {
    sortedItems () {
      return [...this.items.slice(this.cursor % this.items.length, this.items.length), ...this.items.slice(0, this.cursor % this.items.length)]
    },
    items () {
      return this.searches
    }
  }
}
</script>

<style lang="scss" scoped>
  .sky__client-searches-all-button {
    @apply flex justify-center mb-10;
    @screen md {
      @apply block;
    }
    .btn {
      @screen md {
        margin-left: 624px;
      }
    }
  }
  .sky__client-searches-title {
    @apply flex items-end overflow-x-auto pb-16;
    margin-top: -40px;
    margin-bottom: -30px;
    @screen md {
      @apply overflow-x-hidden;
      margin-top: -130px;
      margin-bottom: -80px;
    }
  }
  .sky__search-tile {
    @apply mx-2 z-10 relative block;
    left: -180px;
    min-width: 200px;
    @screen md {
      left: -200px;
    }
    &.sky__search-tile-active {
      min-width: 280px;
      top: 50px;
    }
  }
  @screen md {
    .sky__search-tile {
      left: -200px;
      min-width: 274px;
      &.sky__search-tile-active {
        min-width: 508px;
        top: 50px;
      }
    }
  }
  .sky__searches-arrow-block {
    @apply block border rounded-full overflow-hidden flex items-center justify-center cursor-pointer select-none;
    width: 44px;
    height: 44px;
  }
</style>
