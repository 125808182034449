<template>
  <div class="sky__home-slider">
    <div
      v-for="(slide, index) in slider"
      :key="index"
      class="sky__home-slide"
      :class="{
        active: index === slideIndex
      }"
    >
      <transition name="img-transition">
        <div
          v-show="index === slideIndex"
          class="sky__slider-img"
          :style="{
            backgroundImage: `url(${slide.attributes.big_picture}?resize=true&width=1500&resize=true&format=webp)`,
            backgroundSize: 'cover'
          }"
        />
      </transition>
      <div class="sky__slider-tile-wrapper">
        <transition name="tile-transition">
          <div
            v-show="index === slideIndex"
            class="sky__slider-tile"
          >
            <div class="sky__tile-title">
              {{ slide.attributes.title }}
            </div>
            <div class="text-3xl md:text-4xl font-bold leading-tight mb-4" :style="{color: slide.attributes.subtitle_color}">
              {{ slide.attributes.subtitle }}
            </div>
            <sky-btn
              v-if="slide.attributes.button_text && slide.attributes.link"
              :href="slide.attributes.link[0] === '/' ? undefined : slide.attributes.link"
              :to="slide.attributes.link[0] === '/' ? slide.attributes.link : undefined"
              class="bg-sky_blue text-white"
              arrow-right
              dark
            >
              {{ slide.attributes.button_text }}
            </sky-btn>
          </div>
        </transition>
      </div>
      <div v-if="slide.attributes.produit_1" class="sky__slider-tile-product1-wrapper hidden lg:block">
        <transition name="product1-transition">
          <div
            v-show="index === slideIndex"
            class="sky__slider-tile-product1"
          >
            <img :src="slide.attributes.produit_1" alt="">
          </div>
        </transition>
      </div>
      <div v-if="slide.attributes.produit_2" class="sky__slider-tile-product2-wrapper hidden lg:block">
        <transition name="product2-transition">
          <div
            v-show="index === slideIndex"
            class="sky__slider-tile-product2"
          >
            <img :src="slide.attributes.produit_2" alt="">
          </div>
        </transition>
      </div>
    </div>
    <div class="sky__slider-navigation">
      <div
        v-for="(i, index) in slider.length"
        :key="i"
        class="sky__slider-navigation-item"
        :class="{
          active: index === slideIndex
        }"
        @click="indexWasClicked(index)"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    slider: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      background: require('../../assets/fixtures/slider-img.jpg'),
      slideIndex: 0,
      intervalFn: null
    }
  },
  mounted () {
    this.setInterval()
  },
  beforeDestroy () {
    if (this.intervalFn) {
      clearInterval(this.intervalFn)
    }
  },
  methods: {
    indexWasClicked (index) {
      this.slideIndex = index
      clearInterval(this.intervalFn)
      this.setInterval()
    },
    setInterval () {
      this.intervalFn = setInterval(() => {
        if (this.slider.length - 1 !== this.slideIndex) {
          this.slideIndex++
        } else {
          this.slideIndex = 0
        }
      }, 5000)
    }
  }
}
</script>

<style lang="scss" scoped>
.sky__home-slider {
  @apply relative bg-sky_grey_darker z-0;
  width: 100%;
  @screen md {
    height: 570px;
  }
  .sky__home-slide {
    @apply hidden;
    &.active {
      @apply block;
    }
    @screen md {
      @apply flex;
      &.active {
        @apply flex;
      }
    }
    .sky__slider-img {
      height: 360px;
      width: 100%;
      @screen md {
        @apply absolute left-0 z-10;
        width: 67.75%;
        height: initial;
        @apply top-0 bottom-0;
      }
    }
    .sky__slider-tile-wrapper {
      @screen md {
        width: 392px;
        @apply flex absolute top-0 bottom-0 items-center;
        left: 67.75%;
      }
      .sky__slider-tile {
        @apply bg-white p-10 py-6 z-20;
        @screen md {
          @apply absolute;
          box-shadow: 3px 20px 20px #00000033;
          transform: translateX(-50%);
        }
      }
    }
    .sky__slider-tile-product1-wrapper {
      @screen md {
        width: 320px;
        left: 75%;
        top: 130px;
        @apply absolute z-40;
      }
      .sky__slider-tile-product1 {
        @apply z-30;
        opacity: 1;
      }
      .product1-transition-enter-active {
        @screen md {
          animation: product1-in 0.3s;
        }
      }
      .product1-transition-leave-active {
        @screen md {
          animation: product1-in 0.3s reverse;
        }
      }

      @keyframes product1-in {
        0% {
          transform: translateY(-100%);
        }
        100% {
          transform: translateY(0);
        }
      }
    }
    .sky__slider-tile-product2-wrapper {
      @screen md {
        width: 250px;
        left: 73%;
        top: 10px;
        @apply absolute z-30;
      }
      .sky__slider-tile-product2 {
        @apply p-10 py-6 z-30;
        opacity: 1;
      }
      .product2-transition-enter-active {
        @screen md {
          animation: product2-in 0.5s;
        }
      }
      .product2-transition-leave-active {
        @screen md {
          animation: product2-in 0.3s reverse;
        }
      }

      @keyframes product2-in {
        0% {
          transform: translateY(-100%);
        }
        100% {
          transform: translateY(0);
        }
      }
    }

    .img-transition-enter-active {
      @apply z-10;
      @screen md {
        animation: big-slide-in 1s;
      }
    }
    .img-transition-leave-active {
      transition-duration: 1s;
      @apply z-0;
    }
    .img-transition-leave-to {
      transform: translateX(0%);
      // @apply z-0;
    }

    .tile-transition-enter-active, .tile-transition-leave-active {
      @screen md {
        transition: all 0.3s;
      }
    }
    .tile-transition-enter-active {
      opacity: 1;
      @apply z-30;
    }
    .tile-transition-leave-active {
      opacity: 1;
      @apply z-20;
    }
    .tile-transition-enter {
      opacity: 0;
      @apply z-30;
    }
    .tile-transition-leave-to {
      opacity: 0;
      @apply z-20;
    }
  }
  .sky__slider-navigation {
    @apply absolute flex z-20;
    right: 30px;
    top: 375px;
    @screen md {
      left: 70%;
      bottom: 50px;
      right: initial;
      top: initial;
    }
    .sky__slider-navigation-item {
      width: 10px;
      height: 10px;
      @apply bg-sky_blue mr-2 rounded-full relative cursor-pointer;
      &.active {
        background-color: transparent;
        &::after {
          content: '';
          @apply block absolute border-2 rounded-full;
          border-color: #0097a7;
          left: -3px;
          right: -3px;
          bottom: -3px;
          top: -3px;
        }
      }
    }
  }
}

.sky__tile-title {
  @apply font-funny text-4xl text-6xl mt-10 mb-5;
  line-height: 2.7rem;
  @screen xl {
    @apply text-6xl;
  }
}

@keyframes big-slide-in {
  0% {
    transform: translateY(-100%);
  }
  50% {
    transform: translateY(5%);
  }
  100% {
    transform: translateY(0%);
  }
}
</style>
