<template>
  <div>
    <div class="sky__bio-wrapper flex py-16 text-white">
      <lanterns-parallax
        class="parallax-left hidden lg:block overflow-hidden"
      />
      <div class="sky__bio-container container mx-auto">
        <h2 class="font-funny text-5xl font-light text-center">
          Il était une fois Skylantern
        </h2>
        <h3 class="text-2xl font-bold font-light text-center mb-10">
          La marque n°1 de la décoration de mariage
        </h3>
        <div class="lg:flex text-sm overflow-auto">
          <div class="sky__bio-content px-5">
            <p>
              Bienvenue sur le site de SkyLantern, créateur d’enchantement et d’émerveillement depuis 2007.
              SkyLantern est une marque Française de produits de décoration N°1 en Europe des lanternes volantes,
              de la déco de mariage. Faîtes participer vos convives à votre évènement, impliquez les dans la célébration
              et offrez-leur un moment inoubliable à vos côtés.
            </p>
            <p>
              SkyLantern vous propose en effet de nombreuses lanternes volantes au rendu visuel époustouflant.
              La lanterne volante SkyLantern est sûre et sécurisée. Grâce à des matériaux biodégradables, elle ne laisse aucune trace.
              Le traitement de son papier ignifugé évite toute inflammation.
            </p>
            <p>
              Le lancement de lanterne volante est toujours réussi avec les lanternes SkyLantern,
              la magie est toujours au rendez-vous.
              Rien ne symbolise mieux l’union de 2 êtres
              et la prise en charge de leur destin vers
              une nouvelle vie qu’un joli lâcher de lanterne
              avec les gens qui vous sont proches.
            </p>
          </div>
          <div class="sky__bio-content px-5">
            <p>
              Aucun symbole n’est plus fort que l’espoir que l’on place dans la réalisation de ses rêves et de ses objectifs, quand on voit la lanterne s’envoler vaillamment vers de nouveaux et lointains horizons. Rien ne témoigne mieux de l’affection ou de l’hommage que l’on souhaite rendre à une personne ou un évènement qu’une jolie lanterne s’envolant vers les cieux.
            </p>
            <p>
              SkyLantern vous propose également de nombreuses solutions visuelles pour embellir votre mariage ou votre célébration. Créez une ambiance qui ne ressemble à aucune autre, avec des lanternes de jardin ou des lanternes de table.
            </p>
            <p>
              Ces petits sacs lumineux peuvent accueillir une bougie dont la flamme se reflètera à travers les motifs design, en faisant réfléchir le coloris de la lanterne, telle une aura chaleureuse et apaisante. Ils illumineront et guideront vos invités sur le chemin qui mène à la réception, et aux tables.
            </p>
          </div>
          <div class="sky__bio-content px-5">
            <p>
              Vous trouverez également des lanternes flottantes pour vos points d’eau à l’intérieur comme à l’extérieur : bassins, ruisseaux, aquariums, piscines… Ne négligez aucun détail, laissez l’enchantement s’installer pendant toute la durée de la soirée.
            </p>
            <p>
              Enfin, retrouvez des décorations en papier du plus bel effet. De nombreuses formes et couleurs vont vous permettre de composer une décoration festive et joyeuse, en toute simplicité. Vos convives seront charmés par le spectacle des boules et lampions en papier, disposés en guirlande, en grappe, ou suspendus au plafond.
            </p>
            <p>
              SkyLantern vous propose un voyage au pays de l’émerveillement. Découvrez vite les nombreuses solutions de décoration et d’animation de votre évènement sur le site.
            </p>
          </div>
        </div>
      </div>
      <lanterns-parallax
        class="parallax-left hidden lg:block overflow-hidden"
        style="transform: scale(-1, 1)"
      />
    </div>
  </div>
</template>

<script>
import LanternsParallax from './LanternsParallax'

export default {
  components: {
    LanternsParallax
  }
}
</script>

<style lang="scss" scoped>
  .sky__bio-wrapper {
    @apply relative z-0;
    background: url(../../assets/icons/bg-seo.jpg);
    background-size: cover;
  }
  .sky__bio-content {
    p {
      @apply mb-5 text-justify;
    }
  }
  .parallax-left {
    @apply flex-1 z-0;
    opacity: 1;
  }
  .sky__bio-container {
    @apply z-10;
    opacity: 1;
  }
</style>
