var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showcase)?_c('div',{staticClass:"big-container mx-auto lg:flex justify-center"},[_c('div',{class:{
      'lg:order-last': _vm.right,
      'lg:ml-12': _vm.right,
      'lg:mr-12': !_vm.right,
    }},[_c('div',{staticClass:"sky__showcase-illustration p-12 mb-12 hidden lg:block",style:({
        background: "url(https://d3ajicb1jfu4ua.cloudfront.net/ddc3432d-0962-4a50-8635-44d4a8bc19d8?resize=true&width=500&format=webp)",
        backgroundSize: 'cover'
      })},[_vm._m(0)]),_vm._v(" "),_c('div',{staticClass:"sky__showcase-illustration p-12 mb-12 flex items-center justify-center lg:hidden",style:({
        background: "url(https://d3ajicb1jfu4ua.cloudfront.net/ddc3432d-0962-4a50-8635-44d4a8bc19d8?resize=true&width=500&format=webp)",
        backgroundSize: 'cover'
      })},[_vm._m(1)]),_vm._v(" "),_c('div',{staticClass:"hidden lg:flex",class:{
        'justify-start': _vm.right,
        'justify-end': !_vm.right
      }},[_c('sky-btn',{attrs:{"arrow-right":"","to":"/meilleures-ventes/"}},[_vm._v("\n        Plus de produits\n      ")])],1)]),_vm._v(" "),_c('div',{staticClass:"flex md:flex-wrap mx-auto flex-1 overflow-y-auto",staticStyle:{"max-width":"860px"}},_vm._l((_vm.showcase.hits),function(product){return _c('div',{key:product.sku,staticClass:"sky__product-card w-1/3 px-5 mr-5 md:mr-0"},[_c('product-card',{staticClass:"mb-5",attrs:{"product":product}})],1)}),0)]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-right"},[_c('div',{staticClass:"font-bold text-3xl"},[_vm._v("\n          Nos meilleurs produits pour\n        ")]),_vm._v(" "),_c('div',{staticClass:"font-funny text-5xl ml-2"},[_vm._v("\n          votre événement\n        ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c('div',{staticClass:"font-bold text-2xl"},[_vm._v("\n          Nos meilleurs produits pour\n        ")]),_vm._v(" "),_c('div',{staticClass:"font-funny text-4xl ml-2"},[_vm._v("\n          votre événement\n        ")])])}]

export { render, staticRenderFns }