<template>
  <div class="flex flex-col">
    <main-slider :slider="slides" class="mb-10" />
    <main-categories
      class="md:mb-10 order-first md:order-none"
      :categories="categories"
    />
    <product-showcase
      :showcase="firstShowcase.products"
    />
    <inspiration :inspiration="inspiration" class="mb-10" />
    <client-searches
      :searches="customerSearches"
      class="mb-3"
    />
    <instagram-feed
      :posts="instagramPosts"
    />
    <bio
      style="margin-top: -60px; margin-bottom: -60px;"
    />
    <newsletter-registration
      class="mb-10"
    />
  </div>
</template>

<script>
import MainSlider from '../components/homepage/MainSlider'
import MainCategories from '../components/homepage/MainCategories'
import ProductShowcase from '../components/homepage/ProductShowcase'
import Inspiration from '../components/homepage/Inspiration'
import ClientSearches from '../components/homepage/ClientSearches'
import InstagramFeed from '../components/homepage/InstagramFeed'
import Bio from '../components/homepage/Bio'
import NewsletterRegistration from '../components/homepage/NewsletterRegistration'

export default {
  components: {
    MainSlider,
    MainCategories,
    ProductShowcase,
    Inspiration,
    ClientSearches,
    InstagramFeed,
    Bio,
    NewsletterRegistration
  },
  async asyncData ({ app }) {
    const { data } = await app.$api.get('/home')
    return data
  },
  head () {
    return {
      title: 'N°1 Décoration de mariage - Lanterne Volante - SkyLantern.fr',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Le + grand choix de décoration de mariage : lanternes volantes, guirlandes lumineuses, Bougie LED, lampions et boules japonaises. Livraison 24H'
        }
      ],
      link: [
        {
          rel: 'canonical',
          href: this.$host
        }
      ]
    }
  },
  mounted () {
    this.$gtmEvents.onRegularPageView()
  }
}
</script>
