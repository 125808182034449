<template>
  <div class="overflow-x-auto">
    <h2 class="text-center hidden md:block">
      <span class="font-bold text-3xl">Nos produits</span> <span class="font-funny text-5xl ml-2">en 1 coup d'oeil</span>
    </h2>
    <div class="big-container flex md:flex-wrap justify-between mx-auto mt-4">
      <nuxt-link
        v-for="(category, i) in categories"
        :key="i"
        class="sky__index-category-tile"
        :to="$helpers.makeUri(category.path, 'category')"
      >
        <div class="img-wrapper rounded-full md:rounded-none bg-sky_grey_darker md:bg-white overflow-hidden mb-2">
          <img
            :src="category.attributes.home_menu_picture + '?w=250&h=250&format=webp'"
            :alt="category.attributes.name"
          >
        </div>
        <h3 class="text-center uppercase font-bold md:tracking-widest text-xs mb-6">
          {{ category.attributes.name }}
        </h3>
      </nuxt-link>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .sky__index-category-tile {
    @apply mx-3 block;
    min-width: 80px;
    @screen md {
      @apply px-3;
      @apply mx-0;
      width: 16%
    }
    .img-wrapper {
      transition-duration: 0.2s;
      @screen lg {
        @apply rounded-none;
      }
    }
    &:hover {
      .img-wrapper {
        @screen lg {
          transform: scale(1.1) translateY(-10px);
          // background: rgba(#d6d6d6, 0.3);
        }
      }
    }
  }
</style>

<script>
export default {
  props: {
    categories: {
      required: true,
      type: Array
    }
  }
}
</script>
