<template>
  <div class="sky__inspiration pt-10">
    <div class="bg-sky_grey_darker">
      <div class="container mx-auto lg:flex items-center justify-center">
        <div class="py-10 px-5 lg:w-1/3 flex flex-col items-center lg:items-start text-center lg:text-left">
          <h3 class="leading-tight text-center mb-2 lg:mb-0 lg:text-left">
            <span class="block font-bold text-2xl lg:text-3xl">
              {{ inspiration.attributes.titre }}
            </span>
            <span class="block font-funny text-3xl">
              {{ inspiration.attributes.sous_titre }}
            </span>
          </h3>
          <p style="max-width: 372px;" class="text-sm leading-tight mb-6">
            {{ inspiration.attributes.description }}
          </p>
          <sky-btn v-if="inspiration.attributes.lien" :to="inspiration.attributes.lien" class="hidden lg:block" arrow-right>
            Tous les thèmes
          </sky-btn>
        </div>
        <div class="flex justify-start md:justify-center lg:justify-end relative lg:w-2/3 overflow-x-auto" style="top: -30px;">
          <div class="sky__inspiration-img">
            <img
              :src="`${inspiration.attributes.image}?w=500&format=webp`"
              loading="lazy"
              :alt="inspiration.attributes.titre"
            >
          </div>
          <div class="sky__inspiration-img">
            <img
              :src="`${inspiration.attributes.image_2}?w=500&format=webp`"
              loading="lazy"
              :alt="inspiration.attributes.titre"
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SkyBtn from '../general/SkyLightBtn'
export default {
  components: {
    SkyBtn
  },
  props: {
    inspiration: {
      required: true,
      type: Object
    }
  }
}
</script>

<style lang="scss" scoped>
  .sky__inspiration-img {
    @apply mx-4 flex-1;
    min-width: 200px;
    max-width: 372px;

  }
</style>
