<template>
  <div class="mx-5 lg:mx-0">
    <div class="sky__newsletter-registration container mx-auto p-8 bg-white">
      <div class="lg:flex items-center">
        <div class="lg:mr-20 lg:mb-0 mb-3">
          <h3 class="text-center lg:text-left">
            <span class="block font-bold text-xl">La Newsletter</span>
            <span class="block font-funny text-2xl">Votre dose d'enchantement</span>
          </h3>
        </div>
        <div class="flex-1 lg:flex justify-end">
          <div class="mb-3 lg:mb-0">
            <input
              v-model="email"
              type="text"
              class="block bg-sky_grey_darker pl-6 text-sm focus:outline-none"
              style="height: 46px; width: 604px; max-width: 100%;"
              placeholder="Votre e-mail"
              @keyup.enter="register"
            >
          </div>
          <div class="flex lg:block justify-center">
            <sky-btn :loading="registering" class="bg-sky_blue text-white" dark arrow-right @click="register">
              S'abonner
            </sky-btn>
          </div>
        </div>
      </div>
      <div v-if="registeredResponse" class="text-right text-success flex items-center justify-end">
        <strong>
          Vous recevrez très prochainement votre première Newsletter!
        </strong>
        <div class="ml-3">
          🎉
        </div>
      </div>
      <div v-if="typeof registeredError === 'string'" class="text-right text-red-600 flex items-center justify-end">
        <strong>
          {{ registeredError }}
        </strong>
      </div>
      <div v-else-if="registeredError" class="text-right text-red-600 flex items-center justify-end">
        <strong>
          Une erreur est survenue...
        </strong>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      email: null,
      registering: false,
      registeredResponse: null,
      registeredError: false
    }
  },
  methods: {
    async register () {
      this.registering = true
      try {
        const { data } = await this.$api.post('/newsletter/register', {
          email: this.email
        })
        this.registeredResponse = data
      } catch (error) {
        if (error?.response?.status === 420) {
          this.registeredError = error.response.data
        } else {
          this.registeredError = true
        }
      }
      this.registering = false
    }
  }
}
</script>

<style lang="scss" scoped>
  .sky__newsletter-registration {
    @apply z-10 relative;
    box-shadow: 0px 10px 30px #0000001C;
  }
</style>
