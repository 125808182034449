<template>
  <div v-if="showcase" class="big-container mx-auto lg:flex justify-center">
    <div
      :class="{
        'lg:order-last': right,
        'lg:ml-12': right,
        'lg:mr-12': !right,
      }"
    >
      <div
        class="sky__showcase-illustration p-12 mb-12 hidden lg:block"
        :style="{
          background: `url(https://d3ajicb1jfu4ua.cloudfront.net/ddc3432d-0962-4a50-8635-44d4a8bc19d8?resize=true&width=500&format=webp)`,
          backgroundSize: 'cover'
        }"
      >
        <div class="text-right">
          <div class="font-bold text-3xl">
            Nos meilleurs produits pour
          </div>
          <div class="font-funny text-5xl ml-2">
            votre événement
          </div>
        </div>
      </div>
      <div
        class="sky__showcase-illustration p-12 mb-12 flex items-center justify-center lg:hidden"
        :style="{
          background: `url(https://d3ajicb1jfu4ua.cloudfront.net/ddc3432d-0962-4a50-8635-44d4a8bc19d8?resize=true&width=500&format=webp)`,
          backgroundSize: 'cover'
        }"
      >
        <div class="text-center">
          <div class="font-bold text-2xl">
            Nos meilleurs produits pour
          </div>
          <div class="font-funny text-4xl ml-2">
            votre événement
          </div>
        </div>
      </div>
      <div
        class="hidden lg:flex"
        :class="{
          'justify-start': right,
          'justify-end': !right
        }"
      >
        <sky-btn arrow-right to="/meilleures-ventes/">
          Plus de produits
        </sky-btn>
      </div>
    </div>
    <div class="flex md:flex-wrap mx-auto flex-1 overflow-y-auto" style="max-width: 860px">
      <div
        v-for="(product) in showcase.hits"
        :key="product.sku"
        class="sky__product-card w-1/3 px-5 mr-5 md:mr-0"
      >
        <product-card
          :product="product"
          class="mb-5"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ProductCard from '../general/ProductCard'

export default {
  components: {
    ProductCard
  },
  props: {
    right: {
      required: false,
      default: false,
      type: Boolean
    },
    showcase: {
      required: false,
      default: null,
      type: Object
    }
  }
}
</script>

<style lang="scss" scoped>
  .sky__showcase-illustration {
    @screen lg {
      width: 432px;
      height: 604px;
      box-shadow: 15px 15px 30px #00000029;
    }
  }
  .sky__product-card {
    min-width: 175px;
  }
</style>
