<template>
  <div ref="top" class="relative">
    <client-only>
      <img
        v-for="(lantern, index) in lanternImages"
        :key="index"
        class="absolute top-0 left-0"
        :style="{
          transform: cssTransformation(index)
        }"
        :src="lantern"
        alt=""
      >
    </client-only>
  </div>
</template>

<script>
export default {
  data () {
    return {
      lanternImages: [
        require('../../assets/extract-parallax-lanterns/1.png'),
        require('../../assets/extract-parallax-lanterns/2.png'),
        require('../../assets/extract-parallax-lanterns/3.png'),
        require('../../assets/extract-parallax-lanterns/4.png'),
        require('../../assets/extract-parallax-lanterns/4.png')
      ]
    }
  },
  computed: {
    percentageScrolled () {
      if (!this.$store.state.screen.scroll || typeof window === 'undefined' || !this.$refs.top) {
        return 0
      }

      const result = this.$store.state.screen.scroll - (window.pageYOffset + this.$refs.top.getBoundingClientRect().top)

      return (((result / this.$refs.top.clientHeight) * 100 + 100) / 2) / 100
    },
    cssTransformation () {
      return (index) => {
        if (index === 0) {
          const initialLeft = 0
          const initialTop = 800
          const finalLeft = 100
          const finalTop = 0
          const currentLeft = initialLeft - (this.percentageScrolled * (initialLeft - finalLeft))
          const currentTop = initialTop - (this.percentageScrolled * (initialTop - finalTop))
          return `translate3D(${currentLeft}px, ${currentTop - 200}px, 0)`
        }

        if (index === 1) {
          const initialLeft = 200
          const initialTop = 500
          const finalLeft = -200
          const finalTop = 0
          const currentLeft = initialLeft - (this.percentageScrolled * (initialLeft - finalLeft))
          const currentTop = initialTop - (this.percentageScrolled * (initialTop - finalTop))
          return `translate3D(${currentLeft}px, ${currentTop - 200}px, 0) scale(${1 - (this.percentageScrolled * 0.5)})`
        }

        if (index === 2) {
          const initialLeft = -200
          const initialTop = 500
          const finalLeft = 200
          const finalTop = -200
          const currentLeft = initialLeft - (this.percentageScrolled * (initialLeft - finalLeft))
          const currentTop = initialTop - (this.percentageScrolled * (initialTop - finalTop))
          return `translate3D(${currentLeft}px, ${currentTop - 200}px, 0)`
        }

        if (index === 3) {
          const initialLeft = 100
          const initialTop = 800
          const finalLeft = -200
          const finalTop = -200
          const currentLeft = initialLeft - (this.percentageScrolled * (initialLeft - finalLeft))
          const currentTop = initialTop - (this.percentageScrolled * (initialTop - finalTop))
          return `translate3D(${currentLeft}px, ${currentTop - 200}px, 0px)`
        }

        if (index === 4) {
          const initialLeft = 100
          const initialTop = 400
          const finalLeft = 400
          const finalTop = -200
          const currentLeft = initialLeft - (this.percentageScrolled * (initialLeft - finalLeft))
          const currentTop = initialTop - (this.percentageScrolled * (initialTop - finalTop))
          return `translate3D(${currentLeft}px, ${currentTop - 200}px, 0px)`
        }
      }
    }
  }
}
</script>
